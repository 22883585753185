import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function PaymentMethods() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
      <BaseLayout PageName="Payment Methods">
        <div className="md:max-w-full w-full">
          <TransparentHeader
            headertitle="Payment Methods"
            Subheader="Payment Methods"
            bgimg={`${rpdata?.stock?.[9]}`}
          />
          <div className="py-20">
            <h1 className="text-center text-4xl font-bold mb-10">Pay Now</h1>
            <p className="text-center text- font-bold mb-10">
              We accept online payments by Zelle or Paypal.
            </p>
            {/* <div className="flex flex-col items-center w-full">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.paypal.com/webapps/shoppingcart?flowlogging_id=f1770294e1bc8&mfid=1709229373905_f1770294e1bc8#/checkout/openButton"
                className="bg-[#005EA7] hover:bg-[#4287bd] text-white font-bold py-4 px-4 rounded"
              >
                Pay with Paypal
              </a>
            </div> */}
            <div className="justify-center text-center">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="WPHMG2VVNGX7U"
                />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                className="w-[300px]"
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Buy Now"
                />
              </form>
            </div>
            <p className="text-center text- font-bold mt-10">Charles Velez</p>
            <a href="tel:+19515458822">
              <p className="text-center text-4xl font-bold">951-545-8822</p>
            </a>
          </div>
        </div>
      </BaseLayout>
    );
}

export default PaymentMethods;
